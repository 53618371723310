import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~4],
		"/(auth-admin)/admin": [~5,[2]],
		"/(auth-admin)/admin/feedback": [~6,[2]],
		"/(auth-admin)/admin/flags": [~7,[2]],
		"/(auth-admin)/admin/leagues": [~8,[2]],
		"/(auth-admin)/admin/questions": [~9,[2]],
		"/(auth-admin)/admin/questions/archive": [~11,[2]],
		"/(auth-admin)/admin/questions/archive/[year]-[month]-[day]": [~12,[2]],
		"/(auth-admin)/admin/questions/schedule": [~13,[2]],
		"/(auth-admin)/admin/questions/[id]": [~10,[2]],
		"/(auth-admin)/admin/users": [~14,[2]],
		"/(auth-admin)/admin/users/send-reminder": [~18,[2]],
		"/(auth-admin)/admin/users/[id]": [~15,[2]],
		"/(auth-admin)/admin/users/[id]/answer/[question_id]": [~16,[2]],
		"/(auth-admin)/admin/users/[id]/answer/[question_id]/edit": [~17,[2]],
		"/archive": [~25],
		"/archive/[year]/[month]/[day]": [~26],
		"/(auth-user)/feedback": [~19,[3]],
		"/leaderboard": [~27],
		"/leaderboard/beta-results": [~28],
		"/leaderboard/streaks": [~29],
		"/leaderboard/streaks/beta-results": [~30],
		"/(auth-user)/leagues": [~20,[3]],
		"/login": [~31],
		"/logout": [32],
		"/privacy-statement": [33],
		"/register": [~34],
		"/register/complete": [35],
		"/reset-password": [36],
		"/reset-password/sent": [37],
		"/reset-password/update": [38],
		"/rules": [~39],
		"/(auth-user)/statistics": [~21,[3]],
		"/(auth-user)/statistics/answers": [~22,[3]],
		"/(auth-user)/statistics/global": [~23,[3]],
		"/unsubscribe/[uid]": [~41],
		"/(auth-user)/user/settings": [~24,[3]],
		"/u/[user_name]": [~40]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';